import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { history } from "instantsearch.js/es/lib/routers";
import {
  currentRefinements,
  infiniteHits,
  panel,
  poweredBy,
  searchBox,
  stats,
} from "instantsearch.js/es/widgets";
import filtersConfig from "../filters_component/config";
import hitsConfig from "../hits_component/config";
import infiniteHitsWall from "../hits_component/infinite_hits_wall";
import searchBoxConfig from "../box_component/config";
import refinementsConfig from "../refinements_component/config";
import { Turbo } from "@hotwired/turbo-rails";
import I18n from "../../../../javascript/i18n";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = [
    "branding",
    "hits",
    "originalHits",
    "refinements",
    "searchBox",
    "stats",
  ];
  static values = {
    apiKey: String,
    applicationId: String,
    defaultImage: String,
    domain: String,
    filters: Array,
    hitUrl: String,
    index: String,
    indexKey: String,
    trainingTopics: Array,
  };

  connect() {
    this.search = instantsearch(this.instantsearchConfig);
    this.connectWidgets();
    this.connectFilters();
    this.search.start();
    this.search.on("render", () => {
      this.hitsTarget.parentNode.classList.toggle(
        "c-ihedd--search--hits-searching",
        this.isSearching
      );
    });
  }

  disconnect() {
    if (this.search) this.search.dispose();
  }

  connectWidgets() {
    let widgets = [];

    if (this.hasRefinementsTarget) {
      widgets.push(
        currentRefinements({
          container: this.refinementsTarget,
          ...refinementsConfig,
        })
      );
    }

    if (this.hasStatsTarget) {
      widgets.push(
        stats({
          container: this.statsTarget,
          templates: {
            text(data) {
              return `
                ${I18n.t("search.ui.results", { count: data.nbHits })}
                ${
                  data.query
                    ? ` ${I18n.t("search.ui.for")} "${data.query}"`
                    : ""
                }
              `;
            },
          },
        })
      );
    }

    if (this.hasHitsTarget) {
      const hitsConfigForIndex = hitsConfig[this.indexKeyValue];
      if (!hitsConfigForIndex) {
        console.error(
          `Hits config not defined for indexKey "${this.indexKeyValue}"`
        );
        return;
      }

      let hitsWidget;
      let indexDisplayedAsWall = ["bulletin", "training"];

      if (indexDisplayedAsWall.includes(this.indexKeyValue)) {
        hitsWidget = infiniteHitsWall;
      } else {
        hitsWidget = infiniteHits;
      }

      const defaultImageValue = this.defaultImageValue;
      widgets.push(
        hitsWidget({
          container: this.hitsTarget,
          transformItems(items) {
            return items.map((item) => ({
              ...item,
              default_image: defaultImageValue,
            }));
          },
          ...hitsConfigForIndex,
        })
      );
    }

    if (this.hasSearchBoxTarget) {
      const placeholder =
        this.searchBoxTarget.dataset.placeholder ?? searchBoxConfig.placeholder;
      widgets.push(
        searchBox({
          container: this.searchBoxTarget,
          ...searchBoxConfig,
          placeholder: placeholder,
        })
      );
    }

    this.search.addWidgets(widgets);
  }

  connectFilters() {
    if (!this.hasFiltersElement || !this.hasFiltersValue) return;

    let filtersWidgets = [];
    this.filtersValue.forEach((filter, i) => {
      if (!filtersConfig[filter]) {
        console.error(`Filter not defined in config: ${filter}`);
        return;
      }

      const { widget, title, options } = filtersConfig[filter];
      const filterEl = document.createRange().createContextualFragment(`
        <div class="c-ihedd--search--filters-filter"></div>
      `);
      const container = filterEl.querySelector(
        ".c-ihedd--search--filters-filter"
      );
      this.filtersElement.appendChild(filterEl);
      options["container"] = container;

      // Add training topic attributes to items
      if (options.attribute == "training_topics.title") {
        options["transformItems"] = (items) => {
          return items.map((item) => ({
            ...item,
            ...this.topicAttributes(item.label),
          }));
        };
      }

      const collapsible =
        this.filtersValue.length > 1
          ? {
              collapsed(options) {
                return (
                  i != 0 &&
                  options.items.filter((item) => item.isRefined).length == 0
                );
              },
            }
          : {};

      const panelWidget = panel({
        ...collapsible,
        hidden(options) {
          return options.canRefine === false;
        },
        cssClasses: {
          header: "c-ihedd--search--filters-title",
          collapsedRoot: "c-ihedd--search--filters-collapsed",
          collapseButton: "c-ihedd--search--filters-collapse-button",
        },
        templates: {
          header(data, { html }) {
            return html`${title}`;
          },
          collapseButtonText(options, { html }) {
            return html`<div class="c-ihedd--search--filters-collapse-icon">
              ${options.collapsed ? "❯" : "❯"}
            </div>`;
          },
        },
      })(widget);

      filtersWidgets.push(panelWidget(options));
    });

    filtersWidgets.push(
      poweredBy({
        container: this.brandingTarget,
      })
    );

    this.search.addWidgets(filtersWidgets);
  }

  topicAttributes(label) {
    const topic = this.trainingTopicsValue.find(
      (topic) => label == topic.title
    );

    return topic
      ? {
          color: topic.color,
          icon: topic.icon,
        }
      : {};
  }

  get searchClient() {
    return algoliasearch(this.applicationIdValue, this.apiKeyValue);
  }

  get instantsearchConfig() {
    return {
      indexName: this.indexValue,
      routing: {
        router: history({
          cleanUrlOnDispose: false,
          push: (url) => {
            Turbo.navigator.history.push(new URL(url));
          },
        }),
      },
      searchClient: this.searchClient,
      future: {
        preserveSharedStateOnUnmount: true,
      },
    };
  }

  get isSearching() {
    const state = this.search.renderState[this.indexValue];
    return (
      state.stats?.query !== "" ||
      (this.hasRefinementsTarget && state.currentRefinements.items.length !== 0)
    );
  }

  get filtersElement() {
    return document.querySelector(
      "[data-ihedd--search--algolia-target='filters']"
    );
  }

  get hasFiltersElement() {
    return this.filtersElement !== null;
  }
}
