import I18n from "../../../../javascript/i18n";

// NOTE: if you change this value, also update app/models/training_session.rb
const lastDaysThreshold = 7;

const defaultOptions = {
  showMoreText(data, { html }) {
    return html`<span class="c-ihedd--button-text"
        >${I18n.t("search.ui.see_more_results")}</span
      ><svg
        class="c-icon-root c-icon-move-down c-ihedd--button-arrow"
        role="img"
        style="color: var(--currentColor); "
      >
        <use xlink:href="#c-icon-move-down" />
      </svg>`;
  },
};

// https://stackoverflow.com/a/15289883/1789900
function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export default {
  bulletin: {
    ...defaultOptions,
    cssClasses: {
      list: "c-ihedd--search--hits-items",
      item: "c-ihedd--search--hits-card",
      loadMore: "c-ihedd--button-root c-ihedd--search--hits-more",
    },
    templates: {
      empty() {
        return I18n.t("search.ui.empty");
      },
      item(hit, { html, components }) {
        const separatorColor =
          hit.training_topics.length == 1
            ? hit.training_topics[0].color
            : "martinique";
        return html`<article
          class="c-ihedd--card-root c-ihedd--cards-wall-card c-ihedd--card-with-background"
        >
          <div class="c-ihedd--card-media">
            <div class="c-ihedd--card-thumbnail">
              <img alt="" src="${hit.image || hit.default_image}" />
            </div>
            <div class="c-ihedd--card-labels">
              ${hit.training_topics.map(
                (topic) =>
                  html`<span
                    class="c-ihedd--topic-label-root c-ihedd--card-topic-label"
                    style="--topic-color: var(--${topic.color});"
                    ><svg
                      class="c-icon-root c-icon-${topic.icon} c-ihedd--topic-label-icon"
                      role="img"
                      style="color: var(--currentColor); "
                    >
                      <use xlink:href="#c-icon-${topic.icon}"></use></svg
                    ><span class="c-ihedd--topic-label-text"
                      >${topic.short_title || topic.title}</span
                    ></span
                  >`
              )}
            </div>
          </div>
          <div class="c-ihedd--card-content">
            <a class="c-ihedd--card-link" href="${hit.path || hit.url}"
              >${components.Highlight({ hit, attribute: "title" })}</a
            >
            <hr
              class="c-ihedd--separator-root c-ihedd--card-separator c-ihedd--separator-size-small"
              style="--separator-color: var(--${separatorColor});"
            />
            <span class="c-ihedd--card-date">${hit.human_date}</span>
          </div>
        </article> `;
      },
    },
  },
  content: {
    ...defaultOptions,
    cssClasses: {
      list: "c-ihedd--search--hits-items",
      item: "c-ihedd--search--hits-item",
      loadMore: "c-ihedd--button-root c-ihedd--search--hits-more",
    },
    templates: {
      empty() {
        return I18n.t("search.ui.empty");
      },
      item(hit, { html, components }) {
        return html`
          <div class="c-ihedd--search--hits-kind">${hit.human_kind}</div>
          <a href="${hit.path || hit.url}" class="c-ihedd--search--hits-title"
            >${components.Highlight({ hit, attribute: "title" })}</a
          >
          <hr class="c-ihedd--separator-root c-ihedd--separator-size-small" />
          ${hit.date
            ? html`<div class="c-ihedd--search--hits-date">${hit.date}</div>`
            : ""}
        `;
      },
    },
  },
  person: {
    ...defaultOptions,
    cssClasses: {
      list: "c-ihedd--team-root c-ihedd--team-with-separators",
      item: "c-ihedd--team-item",
      loadMore: "c-ihedd--button-root c-ihedd--search--hits-more",
    },
    templates: {
      empty() {
        return I18n.t("search.ui.empty");
      },
      item(hit, { html }) {
        return html`
          <div class="c-ihedd--team-details">
            <a class="c-ihedd--team-name" href="${hit.path || hit.url}">
              ${hit.full_name}
              <svg
                class="c-icon-root c-icon-angle-right c-ihedd--team-icon"
                role="img"
                style="color: var(--currentColor); "
              >
                <use xlink:href="#c-icon-angle-right"></use>
              </svg>
            </a>
            <div class="c-ihedd--team-title">${hit.title}</div>
          </div>
          <div class="c-ihedd--team-image">
            <img
              class="c-ihedd--mugshot-root"
              src="${hit.image || hit.default_image}"
            />
          </div>
        `;
      },
    },
  },
  training: {
    ...defaultOptions,
    cssClasses: {
      list: "c-ihedd--search--hits-items",
      item: "c-ihedd--search--hits-card",
      loadMore: "c-ihedd--button-root c-ihedd--search--hits-more",
    },
    templates: {
      empty() {
        return I18n.t("search.ui.empty");
      },
      item(hit, { html, components }) {
        const separatorColor =
          hit.training_topics.length == 1
            ? hit.training_topics[0].color
            : "martinique";

        // If limit_date is in less than 7 days, we display a warning
        const limitDateSoon =
          hit.limit_date &&
          dateDiffInDays(new Date(hit.limit_date), new Date()) <=
            lastDaysThreshold;
        return html`<article
          class="c-ihedd--card-root c-ihedd--cards-wall-card c-ihedd--card-with-background"
        >
          <div class="c-ihedd--card-media">
            <div class="c-ihedd--card-thumbnail">
              <img alt="" src="${hit.image || hit.default_image}" />
            </div>
            <div class="c-ihedd--card-labels">
              ${hit.training_topics.map(
                (topic) =>
                  html`<span
                    class="c-ihedd--topic-label-root c-ihedd--card-topic-label"
                    style="--topic-color: var(--${topic.color});"
                    ><svg
                      class="c-icon-root c-icon-${topic.icon} c-ihedd--topic-label-icon"
                      role="img"
                      style="color: var(--currentColor); "
                    >
                      <use xlink:href="#c-icon-${topic.icon}"></use></svg
                    ><span class="c-ihedd--topic-label-text"
                      >${topic.short_title || topic.title}</span
                    ></span
                  >`
              )}
            </div>
          </div>
          <div class="c-ihedd--card-content">
            <a class="c-ihedd--card-link" href="${hit.path || hit.url}"
              >${components.Highlight({ hit, attribute: "title" })}</a
            >
            <hr
              class="c-ihedd--separator-root c-ihedd--card-separator c-ihedd--separator-size-small"
              style="--separator-color: var(--${separatorColor});"
            />
            <span class="c-ihedd--card-date">${hit.human_date}</span>
            <div class="c-ihedd--card-pills">
              ${hit.formats.map(
                (format) =>
                  html`<div class="c-ihedd--pill-root">
                    <span class="c-ihedd--pill-text">${format}</span>
                  </div>`
              )}
              ${limitDateSoon
                ? html`<div
                    class="c-ihedd--pill-root"
                    style="--pill-color: var(--havelock);"
                  >
                    <svg
                      class="c-icon-root c-icon-clock c-ihedd--pill-icon"
                      role="img"
                      style="color: var(--currentColor); "
                    >
                      <use xlink:href="#c-icon-clock"></use></svg
                    ><span class="c-ihedd--pill-text"
                      >${I18n.t("training_sessions.columns.last_days")}</span
                    >
                  </div>`
                : ""}
            </div>
          </div>
        </article>`;
      },
    },
  },
};
